<template>
  <div
    class="userguanli"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="sousuo">
      <div class="div1">
        <span>角色名称</span>
        <el-select class="select" v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="div1">
        <span>部门</span>
        <el-select class="select" v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="div1">
        <span>关键字</span>
        <el-select
          style="width: 100px"
          class="select"
          v-model="value"
          placeholder="ID"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <input class="inputs" type="text" />
      </div>
      <img class="imgs" src="../../assets/images/query.png" alt />
    </div>
    <div class="tables">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" label="ID" width="50"></el-table-column>
        <el-table-column
          prop="roleName"
          label="角色名称"
          width="130"
        ></el-table-column>
        <!-- <el-table-column
          prop="orgId"
          label="所属组织机构ID"
          width="180"
        ></el-table-column>
        <el-table-column prop="delFlag" label="删除标识" width="120">
          <template slot-scope="scope">{{
            scope.row.delFlag ? "有效" : "删除"
          }}</template>
        </el-table-column> -->
        <el-table-column
          prop="createId"
          label="创建人ID"
          width="120"
        ></el-table-column>
        <el-table-column prop="createDate" label="创建日期" width="120">
          <template slot-scope="scope">{{
            scope.row.createDate | dateFormatYYmmdd
          }}</template>
        </el-table-column>
        <el-table-column
          prop="updateId"
          label="修改人ID"
          width="120"
        ></el-table-column>
        <el-table-column prop="updateDate" label="修改日期" width="120">
          <template slot-scope="scope">{{
            scope.row.updateDate | dateFormatYYmmdd
          }}</template>
        </el-table-column>
        <el-table-column
          prop="caozuo"
          label="操作"
          width="270"
          style="display: flex"
        >
          <template slot-scope="scope">
            <img
              class="buts"
              src="../../assets/images/edits.png"
              @click="changeRole(scope.$index, scope.row)"
              alt
            />
            <img
              class="buts"
              src="../../assets/images/del.png"
              @click="deleteRoles(scope.$index, scope.row)"
              alt
            />
            <el-button
              class="details1"
              size="mini"
              @click="detailsRole(scope.$index, scope.row)"
              >详情</el-button
            >
            <el-button
              class="details"
              size="mini"
              @click="roleManagement(scope.$index, scope.row)"
              >角色管理</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="totals">
      <!-- 左 -->
      <div class="left">
        <p>
          总用户数
          <span class="spanto">{{ totalCount }}</span
          >人
        </p>
      </div>
      <!-- 右 -->
      <div class="total">
        <!-- 分页 -->
        <div class="pages">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage1"
            :page-size="this.pageSize"
            layout="total, prev, pager, next"
            :total="this.totalCount"
          >
            <img class="lefts" src="../../assets/images/leftpage.png" alt />
            <img class="rights" src="../../assets/images/rightpage.png" alt />
          </el-pagination>
        </div>
        <!-- 按钮 -->
        <div class="buttons">
          <el-button type="text" @click="add">添加角色</el-button>
          <!-- <el-button type="text" @click="roleManagement">角色管理</el-button> -->
        </div>
      </div>
    </div>

    <!-- 角色管理的弹框 -->
    <div>
      <el-dialog
        title="角色管理"
        :visible.sync="dialogVisible2"
        width="50%"
        :before-close="handleClose2"
      >
        <el-tree
          :data="data"
          show-checkbox
          node-key="id"
          :props="defaultProps"
          :default-checked-keys="seletID"
          ref="tree"
        >
        </el-tree>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="tijiao">提 交</el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 添加角色弹框-->
    <div class="accountadd">
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="clearRoleForm"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item label="角色名称" prop="name">
            <el-input v-model="ruleForm.roleName" style="width: 40%"></el-input>
          </el-form-item>
          <el-form-item label="所属组织机构ID" prop="name">
            <el-input v-model="ruleForm.orgId" style="width: 40%"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="clearRoleForm">取 消</el-button>
          <el-button type="primary" @click="addRoleList">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 点击详情的弹框 -->
    <div class="account">
      <el-dialog
        title="角色详情"
        :visible.sync="dialogVisible1"
        width="50%"
        :before-close="detailsX"
      >
        <el-form
          :model="ruleForm1"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item label="删除标识" prop="name">
            <el-input v-model="ruleForm1.delFlag" style="width: 30%"></el-input>
          </el-form-item>
          <el-form-item label="创建人名称" prop="name">
            <el-input
              v-model="ruleForm1.createName"
              style="width: 30%"
            ></el-input>
          </el-form-item>
          <el-form-item label="修改人名称" prop="name">
            <el-input
              v-model="ruleForm1.updateName"
              style="width: 30%"
            ></el-input>
          </el-form-item>
          <el-form-item label="创建时间" prop="name">
            <el-input
              v-model="ruleForm1.createDate"
              style="width: 30%"
            ></el-input>
          </el-form-item>
          <el-form-item label="修改时间" prop="name">
            <el-input
              v-model="ruleForm1.updateDate"
              style="width: 30%"
            ></el-input>
          </el-form-item>
          <el-form-item label="角色名称" prop="name">
            <el-input
              v-model="ruleForm1.roleName"
              style="width: 30%"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属机构" prop="name">
            <el-input v-model="ruleForm1.orgName" style="width: 30%"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="detailsX">关闭</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import rolemanage from "../system_maintain/rolemanage";
// import addaccount from "../system_maintain/addaccount";
import { api } from "../../api1/config";
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      // 弹框
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      title: "添加角色",
      ruleForm: {
        roleName: "",
        orgId: "",
      },
      ruleForm1: {
        delFlag: "",
        createName: "",
        updateName: "",
        createDate: "",
        updateDate: "",
        roleName: "",
        orgName: "",
      },
      rules: {
        ID: [
          { required: true, message: "请输入ID名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        date2: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
      },
      currentPage1: 1,
      pageSize: 10,
      totalCount: 1000,
      pageCount: "", //网络请求的页码
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      //   table
      tableData: [],
      data: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      checkedKeysArr: [],
      roleID: "", //角色ID
      seletID: [], //选中的id
      aa: [],
      jiequ: [],
      jiequ1: [],
      jiequ2: [],
      jiequ3: [],
      jiequ4: [],
      jiequ5: [],
    };
  },
  // components: {
  //   rolemanage,
  //   addaccount
  // },
  created() {
    this.menulist();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    // loadNode(node, resolve) {
    //   console.log(node.level,node);
    //   if (node.level === 0) {
    //     //获取所有菜单
    //     api.getAllMenu().then((res) => {
    //       console.log("所有菜单：", res.data);
    //       if(res.data.code==200){
    //         return resolve(res.data.result);
    //       }
    //       // this.data=res.data.result;
    //     });

    //   }else{
    //     console.log('二级树加载');
    //     api.getAllMenu({id:node.data.children.id}).then((res) => {
    //       console.log("所有菜单：", res.data);
    //       if(res.data.code==200){
    //         return resolve(res.data.result);
    //       }else{
    //         return resolve([])
    //       }
    //       // this.data=res.data.result;
    //     });

    //   }
    // },
    //添加角色或编辑角色
    addRoleList() {
      //判断
      if (this.title == "添加角色") {
        this.title = "添加角色";
        let { roleName, orgId } = this.ruleForm;
        api.addRoleList({ roleName, orgId }).then((res) => {
          console.log("添加角色:", res.data);
          if (res.data.code === 200) {
            //添加成功
            this.$message({
              message: "恭喜你，添加角色成功",
              type: "success",
            });
            //清空表单数据
            this.clearRoleForm();
            //刷新页面数据
            this.menulist({ pageCount: this.currentPage1 });
          }
        });
      } else {
        console.log("修改角色");
        let { id, roleName, orgId } = this.ruleForm;
        api.changeRoleList({ id, roleName, orgId }).then((res) => {
          console.log("修改角色：", res.data);
          if (res.data.code === 200) {
            this.$message({
              message: "恭喜你，修改角色成功",
              type: "success",
            });
            //清空表单数据
            this.clearRoleForm();
            //刷新页面数据
            this.menulist({ pageCount: this.currentPage1 });
          }
        });
      }
    },
    menulist(page) {
      api.getRoleList({ pageCount: page }).then((res) => {
        console.log("角色列表：", res.data);
        if (res.data.code === 200) {
          this.tableData = res.data.result.data;
          this.totalCount = res.data.result.page.totalCount;
          this.pageSize = res.data.result.page.pageSize;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //修改角色
    changeRole(index, row) {
      this.title = "修改角色";
      this.ruleForm = { ...row };
      this.dialogVisible = true;
    },
    //删除角色
    deleteRoles(index, row) {
      //console.log(index,row);
      this.$confirm("是否删除此信息?", "确认信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api.deleteRoleList({ id: row.id }).then((res) => {
            console.log("删除角色：", res.data);
            if (res.data.code == 200) {
              //删除成功
              this.$message({
                message: "恭喜你，删除角色成功",
                type: "success",
              });
              //清空表单数据
              this.clearRoleForm();
              //刷新页面数据
              this.menulist({ pageCount: this.currentPage1 });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作！",
          });
        });
    },
    //清空表单
    clearRoleForm() {
      this.dialogVisible = false; //隐藏弹框
      this.ruleForm = {
        roleName: "",
        orgId: "",
      };
    },
    //点击添加角色菜单
    add() {
      this.dialogVisible = true;
      this.title = "添加角色";
      this.isShow = false;
    },
    //查询详情
    detailsRole(index, row) {
      console.log(index, row);
      this.roleID = row.id;
      this.dialogVisible1 = true;
      api.detailsRoleList({ id: row.id }).then((res) => {
        console.log("角色详情：", res.data);
        if (res.data.code == 200) {
          let collectTime = res.data.result.createDate;
          this.timeData = new Date(parseInt(collectTime))
            .toLocaleString()
            .replace(/:\d{1,2}$/, " ");
          console.log("时间：", this.timeData);
          let collTime = res.data.result.updateDate;
          this.timeDa = new Date(parseInt(collTime))
            .toLocaleString()
            .replace(/:\d{1,2}$/, " ");
          res.data.result.createDate = this.timeData;
          res.data.result.updateDate = this.timeDa;
          this.ruleForm1 = res.data.result;
        }
      });
    },
    //关闭详情按钮
    detailsX() {
      this.dialogVisible1 = false;
    },
    //角色管理
    roleManagement(index, row) {
      this.roleID = row.id; //角色ID
      //获取角色所有的菜单回显
      api.getRoleAllMenu({ roleId: row.id }).then((res) => {
        // console.log("获取角色所有ID：", res.data);
        if (res.data.code == 200) {
          res.data.result.forEach((ele) => {
            // console.log("ele:", ele);
            if (ele == 9) {
              this.jiequ = res.data.result.splice(
                res.data.result.indexOf(9),
                1
              );
            }
          });
          res.data.result.forEach((ele) => {
            // console.log("ele:", ele);
            if (ele == 54) {
              this.jiequ1 = res.data.result.splice(
                res.data.result.indexOf(54),
                1
              );
            }
          });
          res.data.result.forEach((ele) => {
            // console.log("ele:", ele);
            if (ele == 10) {
              this.jiequ2 = res.data.result.splice(
                res.data.result.indexOf(10),
                1
              );
            }
          });
          res.data.result.forEach((ele) => {
            // console.log("ele:", ele);
            if (ele == 11) {
              this.jiequ3 = res.data.result.splice(
                res.data.result.indexOf(11),
                1
              );
            }
          });
          res.data.result.forEach((ele) => {
            // console.log("ele:", ele);
            if (ele == 36) {
              this.jiequ4 = res.data.result.splice(
                res.data.result.indexOf(36),
                1
              );
            }
          });
          res.data.result.forEach((ele) => {
            // console.log("ele:", ele);
            if (ele == 3) {
              this.jiequ5 = res.data.result.splice(
                res.data.result.indexOf(3),
                1
              );
            }
          });

          this.seletID = res.data.result;
          // console.log("this.seletID:", this.seletID);
        }
      });
      this.dialogVisible2 = true;
      //获取所有菜单
      api.getAllMenu().then((res) => {
        //console.log("所有菜单：", res.data);
        this.data = res.data.result;
      });
    },
    //提交
    tijiao() {
      var aKey = this.getCheckedKeys(
        this.data,
        this.$refs.tree.getCheckedKeys(),
        "id"
      );
      console.log(aKey);

      this.dialogVisible2 = false;
      // let arr = this.$refs.tree.getCheckedKeys();
      // let arrRole = arr.join(",");
      // console.log("---", arrRole);
      //配置角色绑定菜单
      api
        .bangMenuList({
          roleId: this.roleID,
          menuIds: aKey,
        })
        .then((res) => {
          // console.log("配置角色绑定菜单：", res.data);
          if (res.data.code == 200) {
            this.$message({
              message: "配置角色成功",
              type: "success",
            });
          }
        });
    },
    getCheckedKeys(data, keys, key) {
      console.log("shuchu:", data, keys, key);
      var res = [];
      recursion(data, false);
      return res;
      function recursion(arr, isChild) {
        var aCheck = [];
        for (var i = 0; i < arr.length; i++) {
          var obj = arr[i];
          aCheck[i] = false;

          if (obj.children) {
            aCheck[i] = recursion(obj.children, true) ? true : aCheck[i];
            if (aCheck[i]) {
              res.push(obj[key]);
            }
          }

          for (var j = 0; j < keys.length; j++) {
            if (obj[key] == keys[j]) {
              aCheck[i] = true;
              if (res.indexOf(obj[key]) == -1) {
                res.push(obj[key]);
              }
              break;
            }
          }
        }
        if (isChild) {
          return aCheck.indexOf(true) != -1;
        }
      }
    },
    // //点击树节点事件
    // handleNodeClick(checkedKeys) {
    //   console.log("=======checkedKeys", checkedKeys);
    //   console.log('id',checkedKeys.id);
    //   this.checkedKeysArr+=checkedKeys.id+',';
    //   let a=this.checkedKeysArr.substring(0, this.checkedKeysArr.lastIndexOf(','));
    //   // this.aa=a;
    //   function unique(arr){
    //     return Array.from(new Set(arr))
    //   }
    //   this.aa=unique(a.split(",")).join(',');
    //   console.log(this.aa)
    // },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage1 = val;
      this.menulist(val);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleClose2() {
      this.dialogVisible2 = false;
    },
  },
};
</script>

<style lang="less" scoped>
.userguanli {
  // 搜索
  .sousuo {
    // background: red;
    display: flex;
    align-items: center;
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    .div1 {
      margin-right: 20px;
      .inputs {
        width: 231px;
        height: 33px;
        border: 1px solid #e5e5e5;
        margin-left: 5px;
      }
      .inputs:focus {
        outline: 1px solid #e5e5e5; //边框不用border，用outline
      }
      span {
        font-size: 14px;
        color: #909399;
        font-weight: 700;
      }
      .select {
        margin-left: 20px;
      }
    }
    .imgs {
      width: 49px;
    }
  }
  //   table
  .tables {
    position: relative;
    margin-right: 40px;
    margin-left: 25px;
    .tingyong {
      color: rgb(97, 97, 245);
    }
    .buts {
      width: 36px;
      height: 27px;
      margin: 0 10px;
    }
    .el-table {
      // max-height: 500px;
      // overflow: hidden;
      tr {
        th {
          font-size: 14px;
          border: none;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #909399;
        }
        td {
          border: none;
          text-align: center;
          font-size: 14px;
          color: #606266;
        }
      }
      .details {
        height: 30px;
        border-radius: 15px;
      }
    }
    .el-table::before {
      height: 0;
    }
    .details {
      height: 30px;
      position: absolute;
      top: 11px;
      right: -80px;
      border-radius: 15px;
    }
    .details1 {
      height: 30px;
      position: absolute;
      top: 11px;
      right: 15px;
      border-radius: 15px;
    }
  }
  /deep/ .el-table .el-table__cell {
    padding: 12px 0;
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    text-align: center;
  }
  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  /deep/ th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  .totals {
    display: flex;
    justify-content: space-between;
    .left {
      p {
        font-size: 16px;
        display: inline-block;
        margin-right: 80px;
        margin-left: 80px;
        margin-top: 20px;
        color: #606266;
      }
      .spanto {
        color: #92aca7;
        margin-left: 17px;
        margin-right: 12px;
      }
    }
    .total {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 20px;
      margin-right: 200px;
      // 按钮
      .buttons {
        display: flex;
        .el-button {
          width: 130px;
          height: 40px;
          line-height: 40px;
          border-radius: 25px;
          margin-top: 20px;
          background: #d78673;
          color: #ffffff;
          font-size: 16px;
          padding: 0px;
        }
      }
      // page
      .pages {
        display: flex;
        align-items: center;
        .el-pagination {
          padding: 0;
          .el-pager,
          .el-pager li {
            margin-top: 2px;
            color: #afafaf;
            min-width: 50px;
            font-size: 17px;
            font-weight: normal;
          }
          .el-pager li.active {
            color: #d78673;
          }
          .el-pager li:hover {
            color: #d78673;
          }
          img {
            display: inline-block;
          }
          .lefts {
            margin-right: 25px;
          }
          .rights {
            float: right;
            margin-left: 25px;
          }
        }
      }
    }
  }
  // // 弹框
  .el-input__inner {
    width: 97%;
  }
  .account .el-input__inner {
    border: 1px solid #fff;
  }
  // .el-dialog {
  //   border-radius: 18px;
  // }
  // .el-dialog__header {
  //   background: #eaeaea;
  //   border-radius: 18px 18px 0 0;
  // }
  // .el-dialog__close {
  //   color: transparent;
  //   background-image: url("../../assets/images/close.png");
  //   background-repeat: no-repeat;
  //   background-size: 14px;
  // }
  // .el-dialog__body {
  //   background: #eaeaea;
  //   border-radius: 0 0 18px 18px;
  //   padding: 10px 0px 0 0px;
  // }
  // .accountadd {
  //   .el-dialog__header {
  //     background: #ffffff;
  //     border-radius: 18px 18px 0 0;
  //   }
  //   .el-dialog__body {
  //     background: #ffffff;
  //     border-radius: 0 0 18px 18px;
  //     padding: 10px 0px 0 0px;
  //   }
  // }
  /deep/.el-input__inner {
    border-radius: 0;
    height: 33px;
    line-height: 33px;
  }
  /deep/.el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    transition: all 0.3s;
    line-height: 33px;
  }
  /deep/.el-form-item__label {
    color: #909399;
    text-align: center;
  }
  /deep/.el-dialog__body {
    padding: 0;
    margin-left: 2%;
  }
  /deep/.el-table td,
  .el-table th.is-leaf {
    border-bottom: none !important;
  }
  /deep/.el-table .cell {
    text-align: center !important;
  }
  /deep/.el-table thead tr th.is-leaf {
    border: none;
    border-right: none;
  }
}
</style>